html, body {
  height: 100%;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mainContainer {
  background-size: cover;
  text-align: start;
  box-sizing: border-box;
  border-radius: 1rem; 
  align-self: center;
  display: flex;
  flex-direction: column;
}

.login-form-box {
  background-color: white;
  padding-inline: 2.35rem; 
  padding-block: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  width: 25rem; 
  height: 40rem; 
  border: 1px solid #4a5568;
  align-self: center;
  margin-top: 5px;
  overflow-y: auto;
}

.login-form-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.05rem;
  text-align: center;
}

.login-form-field {
  margin-bottom: 1.2rem;
  margin-inline: 1.025rem;
}

.login-form-field-remember-me {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}

.login-form-field-remember-me-checkbox{
  margin-right: 4px;
  border-radius: 2px;
}


.login-form-label {
  display: block;
  color: #4a5568;
  font-size: 1.05rem;
}


.login-form-input {
  width: 100%;
  padding: 0.15rem 0.1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.login-form-checkbox {
  margin-right: 0.75rem;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.forgot-password {
  text-align: left;
  margin-top: 1.5rem;
}

.forgot-password a {
  color: #3182ce;
  font-size: 1.05rem;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.login-form-input {
  width: 100%;
  padding-right: 2.5rem;
  align-items: center;
}

.login-form-button {
  width: 100%;
  color: white;
  padding: 0.65rem 1.5rem;
  border-radius: 0.25rem;
  background-color: #ff5700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
  border: none;
}

.login-form-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed; 
}

.password-toggle-button {
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.error-text {
  color: red;
  font-size: 0.865rem;
  margin-top: 0.25rem;
}

.password-text {
  font-size: 0.65rem;
  color: black;
  line-height: 1.2; 
  margin: 0.5rem 0; 
  max-width: 200px;
  word-wrap: break-word;
}
@media (max-width: 480px) {
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    display: block;
    padding: 1rem;
  }

  .mainContainer {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }

  .login-form-box {
    width: 100%;
    height: auto;
    padding: 1rem;
    margin-top: 0;
    border-radius: 0.5rem;
  }

  .login-form-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .login-form-field {
    margin-bottom: 1rem;
    margin-inline: 0;
  }

  .login-form-label {
    font-size: 1rem;
  }

  .login-form-input {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .login-form-button {
    font-size: 1.1rem;
    padding: 0.75rem;
  }

  .forgot-password {
    margin-top: 1rem;
  }

  .forgot-password a {
    font-size: 0.9rem;
  }

  .password-text {
    font-size: 0.75rem;
    max-width: 100%;
  }

  .error-text {
    font-size: 0.8rem;
  }

  .login-form-field-remember-me {
    margin-bottom: 0.5rem;
  }

  .login-form-field-remember-me-checkbox {
    margin-right: 0.5rem;
    transform: scale(1.2);
  }
}