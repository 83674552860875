@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply overflow-hidden h-screen bg-[url(../public/background.jpg)] bg-center bg-cover bg-no-repeat;
    font-family: 'Montserrat', sans-serif;
  }
  body {
    @apply overflow-auto h-full;
    font-family: 'Montserrat', sans-serif;
  }

  .bg-color {
    background-color: rgba(14, 14, 29, 0) !important;
  }
  .no-margin {
    margin: 0;
  }
  .bold {
    font-weight: bold;
  }
}
