.header {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-content{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.logoutButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.logoutIcon {
  color: white;
  font-size: 38px;
  margin-bottom: 0px;
}

.header .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logoPlaceholder {
  width: 9rem; 
}

@media (max-width: 1280px) {
  .header .logoPlaceholder {
    width: 7rem;
  }
}

@media (max-width: 640px) {
  .header {
  height: 50px;
  }

  .header-actions {

  margin-top: 10px;
}

.logoutIcon {
  color: white;
  font-size: 28px;
  margin-bottom: 10px;
}
}
