body {
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100vh;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 20px;
}

.initialComponent {
  background-color: #fff;
  border-radius: 1.25rem;
  height: auto;
  margin-inline: auto;
  padding-block: 24px;
}

.botTableContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: 20px;
  margin-block: 2px;
}

.botTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.botTable th,
.botTable td {
  padding-block: 6px;
  text-align: left;
  padding-inline: 12px;
}

.botTable th {
  background-color: #ff5700;
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.botTable td {
  color: #ff5700;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border-left: none;
  padding-block: 10px;
}

.botTable td:last-child {
  border-right: none;
}

.botTable th:last-child {
  border-right: 1px solid #ffffff;
  border-top-right-radius: 8px;
}

.botTable th:first-child {
  border-left: 1px solid #ffffff;
  border-top-left-radius: 8px;
}

.\31 Container {
  width: 4.5rem;
  height: 4.5rem;
  align-self: left;
  margin-left: -0.5rem;
  

}

.\32 Container {
  width: 3.5rem;
  height: 3.5rem;
}

.\33 Container {
   width: 3.8rem;
  height: 3.8rem;
  transform: scaleX(-1);
}

.\34 Container {
  width: 4rem;
  height: 4rem;
  transform: scaleX(-1);
}



.actionButtons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.editButton,
.deleteButton {
  background: #ff5700;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: #ffffff;
  padding: 4px 8px;
  margin: 6px;
  border-radius: 8px;
}

.editButton .icon,
.deleteButton .icon {
  margin-left: 6px;
}

.create-button {
  padding: 10px;
  background-color: #ff5700;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.create-button:hover {
  background-color: #ff4500;
}

.botRow {
  cursor: pointer;
}

.botRow.even td {
  background-color: rgba(255, 87, 0, 0.1);
}

.botRow.odd td {
  background-color: white;
}

.botRow:hover td {
  background-color: rgba(255, 87, 0, 0.25);
}

.botRow.selected td {
  background-color: rgba(255, 87, 0, 0.4);
}

@media (max-width: 768px) {
  header {
    font-size: 18px;
  }

  .initialComponent {
    height: auto;
  }

  @media screen and (max-width: 767px) {
  body {
    padding-inline: 5px;
  }

  .initialComponent {
    padding-block: 12px;
  }

  .botTableContainer {
    margin-inline: 5px;
    overflow-x: auto;
  }

  .botTable {
    font-size: 12px;
    margin: 8px 0;
  }

  .botTable th,
  .botTable td {
    padding-inline: 4px;
    padding-block: 6px;
  }

  .botTable th {
    font-size: 12px;
  }

  .botTable td {
    font-size: 11px;
  }

  .\31Container,
  .\32Container,
  .\33Container,
  .\34Container {
    width: 2rem;
    height: 2rem;
  }

  .actionButtons {
    flex-direction: row;
    justify-content: center;
  }

  .editButton,
  .deleteButton {
    padding: 2px 4px;
    margin: 2px;
    font-size: 10px;
    width: 60px; 
    height: 24px; 
  }

  .editButton .icon,
  .deleteButton .icon {
    margin-left: 2px;
    font-size: 10px;
  }

  .create-button {
    padding: 6px;
    font-size: 12px;
    width: 140px;
    margin-bottom: 8px;
  }

  .botTable td:nth-child(2),
  .botTable th:nth-child(2) {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .botTable td:nth-child(3),
  .botTable th:nth-child(3),
  .botTable td:nth-child(4),
  .botTable th:nth-child(4) {
    max-width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .botTable td:nth-child(5),
  .botTable th:nth-child(5) {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
}
