@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap');

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 20px; 
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100%;
  max-width: 70rem;
  margin-bottom: 20px;
}

.chat-container {
  position: relative;
  height: calc(100% - 5.25rem);
  width: 100%;
  max-width: 70rem;
  max-height: calc(100vh - 5.25rem);
  background-color: white;
  border-radius: 1.25rem;
  font-size: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.chat-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 60rem;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  gap: 1rem;
  padding: 1.75rem;
  margin-inline: 0.625rem;
}

.message-container {
  margin-bottom: 1.25rem;
}

.chat-message-row {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: 1.25rem;
}

.audio-div {
  padding-block: 0.8rem;
}

.chat-message {
  padding: 0.5rem;
  background-color: #ff5700;
  border-radius: 0.5rem;
  width: calc(80% - 5rem);
  color: white;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.45);
}

.chat-message p {
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.avatar-1 {
  width: 13.75rem;
  height: 13.75rem;
  margin-left: -5rem;
  margin-bottom: -4rem;
  align-self: flex-end;
  z-index: 1000;
}

.avatar-2 {
  width: 10.75rem;
  height: 10.75rem;
  margin-right: 7rem;
  margin-bottom: 0rem;
  align-self: flex-end;
  z-index: 1000;
}

.avatar-3 {
  width: 10.75rem;
  height: 10.75rem;
  margin-left: -3rem;
  margin-bottom: -2rem;
  align-self: flex-end;
  z-index: 1000;
  transform: scaleX(-1);
}

.avatar-4 {
  width: 10.75rem;
  height: 10.75rem;
  margin-left: -3rem;
  margin-bottom: -2rem;
  align-self: flex-end;
  z-index: 1000;
  transform: scaleX(-1);
}

.followup-row {
  display: flex;
  justify-content: start;
  z-index: 2000;
}

.chat-followups {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.9375rem;
  margin-top: 0.625rem;
  flex: 3;
  font-family: 'Montserrat', sans-serif;
  z-index: 2000;
}

.avatar-name {
  font-size: 4.25rem;
  margin-block: 1.005rem;
  color: white;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  padding: 0.5rem 0;
  font-family: 'Irish Grover', cursive;
}

.chat-followup {
  padding: 0.9375rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.45);
  text-align: center;
  cursor: pointer;
  color: white;
}

.followup-0 {
  background-color: #ffa600;
}
.followup-1 {
  background-color: #3458a4;
}
.followup-2 {
  background-color: #ff5733;
}
.followup-3 {
  background-color: #318a41;
}
.followup-4 {
  background-color: #5733ff;
}
.followup-5 {
  background-color: #ff5733;
}
.followup-6 {
  background-color: #5733ff;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 0.625rem;
  padding: 1.25rem;
  width: 100%;
  z-index: 10000;
}

.go-back-button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background-color: #ff5700;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.go-back-button:hover {
  background-color: #e04e00;
}

.go-back-button svg {
  margin-left: 0.5rem;
}

@media (max-width: 1024px) {
  .chat-container {
    height: calc(100% - 4.5rem);
    font-size: 1.25rem;
    margin-inline: 10px;
  }

  .chat-inner-container {
    min-width: 30rem;
  }

  .chat-message {
    width: calc(90% - 4rem);
  }

  .chat-followup {
    padding: 0.75rem;
    font-size: 1.125rem;
  }

  .avatar-name {
    font-size: 3.5rem;
  }
}

.passwordSettings {
  flex-direction: column;
  text-align: center;
}

.passwordInputContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-block: 20px;
}

.passwordInput {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.passwordInput:focus {
  border-color: #007bff;
  outline: none;
}

.enter-chatbot-button {
  padding-inline: 2.1875rem;
  padding-block: 0.9375rem;
  font-size: 1.5rem;
  border-radius: 2.1875rem;
  background-color: #ff5700;
  color: white;
  cursor: pointer;
  border: 0.125rem solid white;
  align-self: center;
  position: relative;
}

.passwordSettings {
  text-align: center;
}

.passwordInputContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.passwordInput {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.passwordInput:focus {
  border-color: #007bff;
  outline: none;
}

@media (max-width: 480px) {
    body {
    overflow-x: hidden;
  }
  .chatbot-container {
    height: 95vh;
    box-sizing: border-box;
    margin-inline: 2px;
    padding: 5px;
    overflow-x: hidden;
  }

  .settingsTitle {
    font-size: 1.2rem;
  }

  .chat-container {
    width: 100%;
    margin: 0;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
  }

  .chat-messages {
    align-items: flex-start;
    padding: 0.8rem;
    gap: 0.5rem;
    width: calc(100% - 1.75rem);
    margin-right: 0;
    overflow-x: hidden;
  }

  .chat-inner-container {
    min-width: 100%;
  }

  .chat-message {
    width: 100%;
    font-size: 0.9rem;
    padding: 0.375rem;
    box-sizing: border-box;
    position: relative; 
    padding-bottom: 10px; 
  }

  .chat-followups {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 0.5rem;
  }

  .chat-followup {
    width: 100%;
    margin-bottom: 5px;
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .avatar-name {
    font-size: 1.7rem;
    margin: 0;
  }

  .avatar-1, .avatar-2, .avatar-3, .avatar-4 {
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: 50px; 
    right: -35px; 
    z-index: 1001;
  }

  .message-container {
    margin-bottom: 0.625rem;
  }

  .chat-message-row {
    justify-content: flex-start;
    margin-bottom: 0.625rem;
    position: relative;
  }

  .followup-row {
    justify-content: center;
  }

  .audio-div {
    padding-block: 0;
  }

  .audio-div audio {
    width: 85%;
    margin-top: 15px;
  }

  .go-back-button {
    font-size: 0.7rem;
    padding: 0.37rem 0.7rem;
    z-index: 2000;
  }
}