@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.settingsComponent {
  padding: 1.25rem;
  background-size: cover;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.5rem;
  overflow-y: auto;
  max-height: 100vh;
  margin-top: 20px;
  color: #ff5700;
  max-width: 800px;
}
.settingsComponent::-webkit-scrollbar {
  display: none;
}

.headerContainer{
  height: 80px;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.navRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.settingsTitle {
  font-size: 4rem;
  color: white;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;
}

.editBotTitle {
  font-size: 2.3rem;
  color: white;
  white-space: nowrap;
  width: 100%;
}

.logoutButton {
  margin-left: 6px;
}

.langSwitchContainer {
  margin-left: auto;
}

.sendCodeTxt {
  margin-top: 0.625rem;
}

.underline {
  text-decoration: underline;
}

.ageSettings,
.avatarSettings,
.contentSettings,
.share-section,
.avatarPicker,
.voicePicker,
.legalApproval,
.passwordSettings {
  width: 100%;
  max-width: 100%;
  color: #ff5700;
  margin-bottom: 1.25rem;
  background-color: white;
  padding-inline: 1.875rem;
  padding-block: 0.9375rem;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  gap: 1rem;

}

.voicePicker select{
  border: 1px solid #ff5700;
    border-radius: 0.55rem;
    padding: 0.625rem;
    width: 100px;
}

.linkDisclaimer{
  font-size: 0.9rem;
  margin-inline: 12px;
  margin-top: 8px;
}

.contentSettings {
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1.25rem;
  color: #ff5700;
  margin-bottom: 1.25rem;
  padding-inline: 1.875rem;
  padding-block: 0.9375rem;
  width: 100%;
  font-size: 1.5rem;
}

.contentSettings .title {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #ff5700;
}

.voicePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.25rem;
}

.voicePicker select {
  margin-right: 0.5rem;
}

.playButton {
  color: #ff5700;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.voiceOptionRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.legal-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: 'Montserrat', sans-serif;
  font-size: medium;
}

.legal-dialog {
  background-color: white;
  padding: 1.25rem;
  border-radius: 0.55rem;
  width: 80%;
  max-width: 31.25rem;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.legal-dialog h2 {
  margin-top: 0;
}

.legal-dialog p {
  margin: 1.25rem 0;
}

.legal-dialog button {
  background-color: #ff5700;
  color: white;
  border: none;
  padding: 0.625rem 1.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.legalApproval {
  background-color: white;
  padding-inline: 0.425rem;
  padding-block: 0.6rem;
  border-radius: 0.9375rem;
  color: #ff5700;
  font-size: 1.25rem;
  justify-content: space-between;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: small;
}

.legal-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
  padding: 0.925rem 0.25rem;
}

.legal-link:hover {
  color: #0056b3;
}

.disclaimer {
  color: red;
  font-size: 1rem;
  margin-top: 0.225rem;
  font-size: small;
}

.contentCheckbox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.topicOption {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.topicOption input[type="radio"] {
  margin-right: 0.5rem;
}

.customTopicInput {
  margin-left: 0.5rem;
  width: calc(100% - 1.5rem);
}

.customTopicInput:disabled {
  background-color: #f0f0f0;
  color: #888;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0.875rem;
}

.contentCheckbox label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-radius: 0.75rem;
}

.contentCheckbox input {
  margin-right: 0.625rem;
  border: 1px solid #ff5700;
  background-color: white;
  border-radius: 0.75rem;
}

.ageSettings select,
.avatarSettings input,
.setting input[type="text"],
.setting input[type="radio"] + label {
  font-size: 1.25rem;
  padding-right: 2.5rem;
  border-radius: 0.75rem;
  border: 1px solid #ff5700;
  box-sizing: border-box;
  margin-left: 1.25rem;
  color: #ff5700;
}

.ageSettings select:focus,
.avatarSettings input:focus,
.setting input[type="text"]:focus {
  border-color: #ff5700;
  outline: none;
  color: #ff5700;
}

.setting input[type="radio"] {
  margin-right: 0.625rem;
  color: #ff5700;
}

.passwordInputContainer {
  display: flex;
  gap: 5px;
}

.backButton {
  padding: 10px 20px;
  background-color: #ff5700;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-end;
  border: 1px solid #ffffff;
  z-index: 1000;
  flex: 1;
  margin-right: auto;
  align-self: center;
}

.backButton:hover {
  background-color: #ff5700;
}

.passwordInput {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.passwordInput:focus {
  border-color: #007bff;
  outline: none;
}

.createDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.createButton {
  margin: 20px;
  padding: 15px 25px;
  background-color: #ff5700;
  color: white;
  border: none;
  border-radius: 1.25rem;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #ffffff;
}

.createButton:disabled {
  background-color: #ff5700;
  cursor: not-allowed;
  opacity: 0.7;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.share-section {
  display: inline-grid;
  background-color: white;
  border-radius: 1.25rem;
  padding: 0.625rem;
  overflow: hidden;
  width: 100%;
}

.share-buttons {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-block: 1rem;
}

.share-buttons buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.625rem;
  font-size: 1rem;
  border: none;
  border-radius: 50%;
  background-color: #ffffff;
  color: #ff5700;
  cursor: pointer;
  margin-inline: 0.3125rem;
}

.share-buttons button {
  background-color: #ff5700;
  color: white;
  padding: 0.625rem;
  border-radius: 50% ;
}

.share-buttons button:hover {
  background-color: #ff5700;
}

.share-buttons p {
  font-size: 0.95rem;
  margin-top: 0.5rem;
  color: #ff5700;
  text-align: center;
  align-self: center;
}

.copy-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ff5700;
  border-radius: 0.55rem;
  margin-inline: 2rem;
  padding-inline: 8px;
  margin-top: 1.25rem;
  flex-wrap: wrap;
  overflow: hidden;
  color: #ff5700;
}

.copy-button {
  color: #ff5700;
  font-size: 1.8125rem;
  align-self: center;
}

.copy-section input {
  padding: 0.9375rem;
  font-size: 1.45rem;
  border: none;
  border-radius: 0.55rem;
  padding-right: 2.5rem;
}

.copy-section-text {
  text-align: left;
  white-space: normal;
  max-width: 28.125rem;
  word-wrap: break-word;
  margin: 0.625rem;
  font-family: 'Montserrat', sans-serif;
  font-size: small;
}

.avatarPicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.25rem;
}

.voiceSelectContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.15rem;
  width: 100%;
  margin-bottom: 1.25rem;
}

.voiceSelectContainer select{
  width: 400px;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.25rem;
  width: 100%;
  margin-bottom: 1.25rem;
}

.avatarOption {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 5px 10px;
}

.avatarOption input[type="radio"] {
  margin-right: 0.5rem;
}

.lottieContainer {
  width: 100px;
  height: 100px;
}

.avatarOption.selected {
  border-color: #ff5700;
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.voicePicker {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.voiceOption {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.voiceOption.selected {
  border-color: #ff5700;
}

.voiceLabel {
  margin-left: 10px;
  font-size: 1.25rem;
}

.setting input[type="text"],
.setting input[type="radio"] + label {
  font-size: 1.25rem;
  padding-right: 2.5rem;
  border-radius: 0.75rem;
  border: 1px solid #ff5700;
  box-sizing: border-box;
  margin-left: 1.25rem;
  color: #ff5700;
}

.setting input[type="radio"] {
  margin-right: 0.625rem;
  color: #ff5700;
}
@media screen and (max-width: 767px) {
  body {
    overflow-y: auto;
  }

  .settingsComponent {
    padding: 1rem;
    font-size: 1rem;
    margin-top: 10px;
    max-width: 100%;
  }

  .headerContainer {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .navRow {
    flex-direction: column;
    align-items: center;
  }

  .settingsTitle {
    font-size: 2rem;
    white-space: normal;
  }

  .editBotTitle {
    font-size: 1.5rem;
    white-space: normal;
  }

  .logoutButton {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .langSwitchContainer {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .ageSettings,
  .avatarSettings,
  .contentSettings,
  .share-section,
  .avatarPicker,
  .voicePicker,
  .legalApproval,
  .passwordSettings {
    padding: 1rem;
    font-size: 1rem;
    gap: 0.5rem;
  }

  .voicePicker select {
    width: 100%;
    max-width: 200px;
  }

  .contentCheckbox {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 1.2rem;
  }

  .contentCheckbox label {
    font-size: 1rem;
  }

  .ageSettings select,
  .avatarSettings input,
  .setting input[type="text"],
  .setting input[type="radio"] + label {
    font-size: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    width: 100%;
    max-width: 200px;
  }

  .passwordInputContainer {
    justify-content: center;
  }

  .passwordInput {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .createDiv {
    flex-direction: column;
  }

  .createButton {
    margin: 10px;
    padding: 10px 20px;
    font-size: 14px;
  }
  .buttons{
    flex: 2;
  }

  .share-buttons {
    flex-wrap: wrap;
    text-align: center;
    align-self: center;

  }

  .share-buttons button {
    margin: 0.25rem;
    align-self: center;
  }


  .share-buttons p {
    font-size: smaller;
    width: 90%;
  }

  .copy-section {
    margin-inline: 0.5rem;
    font-size: smaller;
  }

  .copy-button {
    font-size: medium;
  }

  .copy-section input {
    padding: 0.5rem;
    font-size: 1rem;
    width: 70%;
    font-size: small;
  }

    .copy-section-text{
    width: 70%;
    font-size: small;
  }
  
  .voiceSelectContainer select {
    width: 100%;
    max-width: 200px;
  }

  .avatarContainer {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .lottieContainer {
    width: 80px;
    height: 80px;
  }

  .voiceLabel {
    font-size: 1rem;
  }

  .legal-dialog {
    width: 90%;
    padding: 1rem;
  }
  .linkDisclaimer{
  font-size: small
}
}